@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1158px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1100px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-top: 40px;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 50px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    flex-basis: 373px;
    flex-shrink: 0;
  }
}

.sectionAvatar {

  @media (--viewportMedium) {
    margin-right: 20px;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
    width: 152px;
    height: 152px;
  }

  @media (--viewportLarge) {
    width: 246px;
    height: 246px;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  color: var(--matterColorLight);
  display: flex;
  align-items: center;
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  display: grid;
  padding-left: 12px;
  gap: 2px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  font-size: 26px;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }

  @media (--viewportLarge) {
    font-size: 48px;
  }
}

.subTitle {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;

  @media (--viewportLarge) {
    margin-top: 20px;
    font-size: 20px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  position: relative;
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 60px;
  }
}

.lessContent {
  max-height: 220px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(var(--matterColorLight), var(--matterColorLight),rgba(255,255,255,0));
  mask-image: linear-gradient(var(--matterColorLight), var(--matterColorLight),rgba(255,255,255,0));
}

.blurText {
  position: absolute;
  bottom: 45px;
  left: 0;
  right: 0;
  height: 100px;
  background-image: linear-gradient(to bottom, transparent, var(--matterColorBright));
}

.descriptionTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorSecondForAvatar);
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorSecondForAvatar);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 62px;
  }
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorSecondForAvatar);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  composes: h3 from global;
  color: var(--matterColorSecondForAvatar);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.backgoundBannerOverlay {
  background: url('../../assets/listingHeroMountainOverlay.svg') no-repeat right bottom;
  width: 400px;

  position: absolute;
  right: 10px;

  height: 200px;
  background-size: 50%;

  @media (--viewportMedium) {
    height: 250px;
    background-size: 70%;
    right: 40px;
  }

  @media (--viewportLarge) {
    height: 340px;
    background-size: 90%;
  }
}

.sectionBanner {
  display: flex;
  background: linear-gradient(0deg, var(--matterColorFirstForBackground) 0%, var(--matterColorSecondForBackground) 100%);
  padding: 0 24px;

  height: 200px;

  @media (--viewportMedium) {
    height: 250px;
  }

  @media (--viewportLarge) {
    height: 340px;
    margin: 30px 36px 0px;
    padding: 0 24px;
    position: relative;
  }
}

.coachInformation {
  width: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1158px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1100px;
    padding: 0;
  }
}

.validated {
  font-size: 12px;
  margin-left: 5px;

  @media (--viewportMedium) {
    margin-left: 10px;
  }
}

.readMoreButton {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 155px;
  min-height: fit-content;
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.angleDown {
  display: inline-block;
  background: url('../../assets/angleDown.png') no-repeat center;
  background-size: 100%;
  height: 20px;
  width: 20px;
}

.angleUp {
  transform: rotate(180deg);
}

.vimeoVideo {
  position: relative;
  width: 100%;
  padding: 70% 0 0 0;
  position: relative;
}

.vimeoVideo iframe,
.vimeoVideo object,
.vimeoVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.introductionVideo {
  width: 100%;
  height: 250px;

  @media (--viewportMedium) {
    height: 345px;
  }
}

.videoError {
  color: var(--failColor);
}

.fieldTitle {
  composes: marketplaceModalTitleStyles from global;
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  line-height: normal;
}

.fieldValue {
  display: block;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
  font-weight: var(--fontWeightRegular);
  line-height: normal;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  text-decoration: underline;
}

.row {

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}

.questionField {
  margin-top: 30px;
}

.agreementModal {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none;

  height: 100%;
  padding: 65px 30px 30px;

  @media (--viewportMedium) {
    flex-basis: 700px;
    flex-grow: 0;
    height: 80vh;

    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    border-bottom: 8px solid var(--marketplaceColor);
  }
}

.agreementModalContent {
  @media (--viewportMedium) {
    overflow-y: scroll;
  }
}

.modalCloseButton {
  composes: marketplaceModalCloseStyles from global;

  justify-content: flex-end;
  width: 100%;
  background-color: var(--matterColorLight);
}

.button {
  composes: button buttonFont buttonText buttonBorders from global;

  color: var(--matterColorLight);

  background-color: var(--matterColorColorful);

  border-radius: 5px;

  &:hover, &:focus {
    background-color: var(--matterColorColorfulDark);
  }
}

.wrapperButton {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 0;
  }
}

.customButton {
  border-radius: 5px;
}

.listingInstructionTitle {
  color: var(--marketplaceColor);
  font-size: 35px;
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
}

.listingInstructionBody {
  text-align: center;
  font-size: 24px;
  line-height: 35px;
  font-weight: var(--fontWeightRegular);
  margin-top: 50px;
  margin-bottom: 40px;

  & a {
    color: var(--matterColorRed);
  }
}

.listingInstructionModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
  @media (--viewportMedium) {
    flex-basis: 508px;
  }
}